.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    border: none;
    border-radius: 7px;
    background-color: var(--dark);
    color: var(--white);
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
    border: none;
    border-radius: 7px;
    background-color: var(--dark);
    color: var(--white);
}

.react-datepicker-popper .react-datepicker__navigation {
    padding: 12px;
    /* background-color: red; */
}

.react-datepicker__navigation--next {
    color: #03C962 !important;
}

.react-datepicker {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
    border: none !important;
    font-family: "Inter" !important;
}

.react-datepicker__header {
    font-weight: bold;
    margin-top: 0;
    font-size: 0.944rem;
    color: #03C962;
    background: white !important;
}


.react-datepicker__day-name {
    color: #3C3C434D !important;
}


.react-datepicker__day {
    color: #03C962 !important;
    font-size: 0.8rem;
    font-weight: bold;
}

.react-datepicker__day .react-datepicker__day--today {
    border-radius: 16px;
    border: solid 2px var(--brand) !important;
    background-color: #03C962 !important;
    color: white !important;
    width: 30px;
    height: 30px;
}

.react-datepicker__current-month {
    color: var(--dark) !important;
}

/* .react-datepicker__day.react-datepicker__day--today {
    border-radius: 16px;
    background-color: #03C962 !important;
    color: white !important;
    width: 32px;
    height: 32px;
} */

.react-datepicker__day.react-datepicker__day--selected {
    border-radius: 16px;
    background-color: #03C962 !important;
    color: white !important;
    width: 32px;
    height: 32px;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    border: none;
    border-radius: 7px !important;
    background-color: var(--brand) !important;
    color: var(--dark) !important;
}