.head {
    margin: 0px 10px;
}

.workShop {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 140px;
}

.main_hero_paragraph {
    text-align: center;
    margin-bottom: 0.75rem;
}

.svg_wrapper {
    display: flex;
    flex-direction: column;
    width: 2em;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 2em;
    border: 0.075rem solid #263238;
}

.workShop_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.workShop_head p {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #263238;
}

.add {
    background: #E2FBD7;
    padding: 8px;
}

.workshop_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.workShop_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    background: #F1F1F1;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
}

.contact_lines {
    align-items: center;
    display: flex;
    padding: 10px 15px;
    margin-top: 10px;
}

.contact_lines svg {
    margin-right: 20px;
}

.pDetails p {
    margin-bottom: 10px;
}

.contact p {
    margin-bottom: 10px;
}

.pDetails_name {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #5D5FEF;
}

.contact_id {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #3C3E3F;
}

.workShopResultList {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #FFFFFF;
    padding: 12px 0px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background: var(--color-white, #FFF);
    height: 150px;
    width: 100%;
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.08); */
}

.icon {
    display: flex;
    gap: 6px;
    width: 100%;
    border-radius: 4px;
    padding: 0.4375rem 0.375rem;
    justify-content: center;
    align-items: center;
    background: var(--color-green-104, #03C962);
}


.price_info {
    margin-right: 0.75rem;
    display: flex;
    width: 4.25em;
    gap: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.price p {
    color: #393F42;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: strikethrough;
}

.icon p {
    color: var(--white, #FFF);

    /* B2B */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.workshopImgWrapper {
    width: 5.125em;
    height: 4.75em;
    display: flex;
    margin-left: 8px;
}

.workshopImgWrapper img {
    width: 100%;
    height: auto;
}

a {
    color: #3C3E3F;
}

.desc h4 {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0px;
}

.desc p {
    color: var(--dark, #263238);
    /* Body 4 Centre */
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.desc {
    display: flex;
    gap: 1.125rem;
    flex-direction: column;
    flex: 1;
    margin-left: 16px;
}

.ratings {
    align-items: center;
    display: flex;
}

.ratings p {
    color: #393F42;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.filter {
    margin-bottom: 20px;
}

.spareParts {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.spareParts .para {
    color: var(--Dark, #263238);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.spareParts .para2 {
    color: #008069;
    font-family: Roboto;
    text-align: end;
    text-decoration: none;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.spareParts_inner {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
}



.spareParts_inner_2 {
    display: flex;
    gap: 5px;
    width: 100%;
    overflow: auto;
    align-items: flex-start;
}

.spareParts_filter {
    display: flex;
    gap: 15px;
    width: 100%;
    overflow: auto;
    align-items: flex-start;
}

.spareParts_filter div {
    display: flex;
    width: 180px;
    flex-shrink: 0;
    padding: 6px 12px;
    border: 1px solid black;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
}

.spareParts_filter div p {
    color: var(--Dark, #263238);
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.spareParts_card_2 {
    display: flex;
    width: 160px;
    height: 280px;
    flex-shrink: 0;
    flex-basis: 170px;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    border-radius: 9px;
    border: 0.75px solid var(--divider-light-1-px-60, rgba(228, 228, 228, 0.60));
    background: var(--colors-neutral-white, #FFF);
}

.spareParts_card {
    display: flex;
    width: 160px;
    height: 280px;
    flex-grow: 0;
    flex-basis: 170px;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 9px;
    border: 0.75px solid var(--divider-light-1-px-60, rgba(228, 228, 228, 0.60));
    background: var(--colors-neutral-white, #FFF);
}

.spareParts_category_card {
    display: flex;
    width: 200px;
    height: 250px;
    flex: 1;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 9px;
    border: 0.75px solid var(--divider-light-1-px-60, rgba(228, 228, 228, 0.60));
    background: var(--colors-neutral-white, #FFF);
}

.spareParts_category_card .spareParts_card_image_wrapper {
    width: 100%;
    align-self: center;
    height: 150px;
}

.spareParts_category_card .spareParts_card_image_wrapper img {
    object-fit: contain;
    object-position: center;
}

.spareParts_card_image_wrapper {
    width: 100%;
    /* flex: 1; */
    height: 150px;
    display: flex;
}

.spareParts_card_image_wrapper_inner {
    height: auto;
    display: flex;
    width: 100%;
}

.spareParts_card_image_wrapper_inner img {
    width: 100%;
    border-radius: 12px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    height: auto;
    display: block;
}

.spare_parts_name_wrapper {
    display: flex;
    gap: 16px;
    width: 100%;
    flex-direction: column;
}

.spareParts_name {
    display: flex;
    gap: 1rem;
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

/* .spareParts_name div {
    display: flex;
} */

.spareParts_category_card .cartBtn {
    padding: 0.805rem 0rem;

}

.cartBtn {
    display: flex;
    width: 100%;
    padding: 0.375rem 0rem;
    justify-content: center;
    align-items: center;
    gap: 4.1875rem;
    border-radius: 4px;
    background: var(--color-green-104, #008069);
}

.spareParts_name .cartBtn p {
    color: var(--color-white, #FFF);
    font-family: Circular Std;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.spareParts_name p {
    color: var(--Dark, #263238);
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.search_input {
    display: flex;
    flex: 1;
    width: 100%;
}

/* .search_input input {
    display: flex;
    flex: 1;
    width: 100%;
} */

.spareParts_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 30px
}

.spareParts_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.875rem;
}

.form {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}


.cart_button {
    display: flex;
    width: 95%;
    padding: 14px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: fixed;
    bottom: 80px;
    border-radius: 6px;
    background: var(--color-green-02, #D7FBDB);
}

.cart_button p {
    color: var(--color-green-104, #008069);
    text-align: center;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.cart_button p:nth-child(1) {
    color: var(--color-green-104, #008069);
    text-align: center;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    /* display: flex; */
    line-height: normal;
}

.cart_wrapper {
    width: 100%;
    margin-top: 50px;
    height: 70vh;
    margin-bottom: 15px;
    display: flex;
    gap: 1.5625rem;
    flex-direction: column;
    overflow-y: scroll;
}

.cart_item {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: flex-end;
    align-items: center;
    gap: 39px;
    background: var(--color-white, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.08);
}

.item_image {
    display: flex;
    width: 111px;
    height: 100px;
    border-radius: 6px, 0px, 0px, 6px;
}

.item_image .item_image_wrapper {
    display: flex;
    height: auto;
    width: 100%;
}

.item_image .item_image_wrapper img {
    width: 100%;
    height: auto;
}

.item_info {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.item_info p {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.item_info p:nth-child(2) {
    color: #939393;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: 28px;
}

.item_info p:nth-child(3) {
    color: #393F42;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.list_action_buttons {
    display: flex;
    width: 68px;
    height: 76px;
    padding: 0px 8px 0px 4px;
    flex-direction: column;
    align-items: flex-end;
    gap: 39px;
    flex-shrink: 0;
}

.list_action_quantity_buttons {
    display: flex;
    width: 64px;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
}

.total {
    margin: 0px 0px;
    margin-top: 20px;
    background-color: #FFFF;
}

.total div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.total div p {
    margin: 0px 8px;
    font-size: 16px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    line-height: 26px;
    /* identical to box height, or 162% */


    color: #393F42;
}

.total div h4 {
    margin: 0px 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: right;

    color: #393F42;
}

.grandTotal {
    background: #D7FBDB;
    padding: 4px 2px;
    display: flex;
    align-items: center;
}

.grandTotal p {
    margin: 12px 8px;
    font-size: 16px;
}

.grandTotal h4 {
    font-size: 24px !important;
    margin: 0px 8px;
}

.workshop_list_wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem;
    width: 100%;
    overflow: auto;
    height: 45vh;
}

.spareParts_wrapper_categories {
    display: flex;
    width: 100%;
    border: 1px solid #BABABA;
    padding: 15px 12px;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    border-radius: 8px;

}

.spareParts_wrapper_categories p {
    color: var(--Color-dark-grey-1, #3C3E3F);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 5 B Left */
    font-family: "Circular Std";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.categories_list {
    overflow: auto;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    display: flex;
}

.searchInputPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #FFFF;
    gap: 0.75rem;
    overflow: auto;
}

.search_input_container {
    display: flex;
    flex-direction: column;
    padding: 12px 0px;
    border-radius: 0.75rem;
}

.search_input_wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.categories_list_specifications {
    display: flex;
    background-color: red;
    padding: 0.5rem 0.75rem 1rem 0.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    background: var(--Color-Grey-101, #FCFCFC);
}

.categories_list_specifications input {
    display: flex;
    padding: 8px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: 0.5px solid #BABABA;
    flex: 1 0 0;
}

.categories_list_specifications div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
}

.categories_list_specifications p {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 3 Left */
    font-family: "Circular Std";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 450;
    line-height: 1.5rem;
    /* 171.429% */
}

.categories_list_categories p {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 2 Left */
    font-family: "Circular Std";
    font-size: 1rem;
    font-style: normal;
    font-weight: 450;
    line-height: 1.625rem;
    /* 162.5% */
}

.categories_list_categories {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
}

.categories_list_category_list {
    width: 100%;
}

.categories_list_category_list_items {
    display: flex;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex: 1 0 0;
    width: 100%;
    background-color: #fbfbfb;
    border-bottom: 0.3px solid #F1F1F1;
}

.categories_list_category_list_items p {
    color: var(--typography-text-primary, #202020);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 3 Left */
    font-family: "Circular Std";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 450;
    line-height: 1.5rem;
    /* 171.429% */
}

.categories_list_category_list_items_inner {
    display: flex;
    padding-right: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-left: 20px;
}

.categories_list_category_list_items_inner div {
    display: flex;
    gap: 8px;
    align-items: center;
}

.categories_list_category_list_items_inner_image {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: lightgray;
}

.header_img {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.header_img_wrapper {
    display: inline-block;
    width: 320px;
    height: 136px;
    padding: 0.4375rem 0.75rem;
}

.header_img_wrapper_inner {
    width: 100%;
    height: auto;
    display: flex;
}

.header_img_wrapper img {
    width: 100%;
    height: auto;
}

.header_text {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0.4375rem 0rem;
}

.header_text div {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.header_text div h1 {
    color: var(--Color-dark-grey-1, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 1rem;
    margin: 0px;
    text-align: start;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.header_text p {
    color: var(--Color-dark-grey-1, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}