@media only screen and (max-width: 920px) {
    .lowerNavBar {
        background-color: #FFFFFF;
        align-items: center;
        width: 100%;
        display: flex;
        position: fixed;
        height: 30px;
        z-index: 10000;
        bottom: 0;
    }

    .lowerNavBar_inner {
        display: flex;
        background-color: #FFFFFF;
        justify-content: space-between;
        padding: 0px 10px;
        height: 60px;
        margin-bottom: 50px;
        width: 100%;
        align-items: center;
    }

    .lowerNavBar_inner div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lowerNavBar_inner div a {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .lowerNavBar_inner div a p {
        color: #BABABA;
    }

    .lowerNavBar_inner div a {
        text-decoration: none;
        /* color: green; */
    }

    .lowerNavBar_inner div .active svg {
        fill: #03C962;
    }

    .lowerNavBar_inner div .active svg path {
        fill: #03C962;
    }

    .lowerNavBar_inner div .active p {
        color: #03C962;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
    }

    .lowerNavBar_inner div svg {
        width: 60px;
        height: 22px;
        fill: #BABABA;
    }

    .lowerNavBar_inner div p {
        font-weight: 400;
        font-size: 12px;
    }

}