.App {
  text-align: center;
}

.image-item {
  margin: 10px 0 10px 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.menu {
  z-index: 100000;
  position: fixed;
  /* height: 100vh; */
  width: 100%;
  background-color: rgba(255, 255, 255, 0.606);
}

.body {
  display: none;
}

.carousel {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 12px; */
  height: 6.75em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 920px) {
  .body {
    display: flex;
    flex-direction: column;
  }
}

.gmnoprint {
  position: absolute;
  top: 30%;
  right: 10px;
  /* Adjust this value to control the distance from the right edge */
  /* transform: translateY(-50%); */
  z-index: 1;
  /* Ensure it's above the map */
}