.head {
    display: flex;
    height: 40px;
    padding: 8px 15px;
    align-items: center;
    gap: 65px;
    align-self: stretch;
    background-color: #F1F1F1;
    width: 100%;
}


.home_live_metrics {
    /* margin: 0px 15px; */
    width: 100%;
    margin-bottom: 30px;
}


.head div h3 {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.info h4 {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.info p {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}