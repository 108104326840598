.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000000;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.75);
}

.modal {
    position: relative;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(141, 140, 140, 0.2);
    margin: auto;
    width: 327px;
    z-index: 10000000;
    overflow: hidden;
}


.loader {
    position: relative;
    margin: auto;
    width: 327px;
    z-index: 10000000;
    overflow: hidden;
    border: none;
    background-color: transparent;
}

.modal_subscription {
    position: fixed;
    top: 10vh;
    margin: 0px 15px;
    z-index: 100;
    overflow-y: scroll;
    height: 80vh;
}

.header {
    background: white;
    text-align: center;
    color: black;
    margin: 0;
    margin-top: 20px;
}

.svg {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
}

.content {
    padding: 0rem 1rem;
    margin: 20px 0px;
    text-align: center;
    color: #263238;
}

.actions {
    padding: 1rem;
    display: flex;
    align-items: center;
}

.actions div {
    width: 100%;
    margin: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__options {
    display: flex;
    flex-direction: column;
    margin: 15px 4px;
    align-items: flex-end;
}

.login__options .login__options_inner p {
    color: #3C3E3F;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
}

.login__options .login__options_inner a {
    text-decoration: none;
    font-weight: 700;
    color: #03C962;
    font-size: 14px;
    display: flex;
}

.form {
    margin: 0px 20px;
}

.login__options_inner {
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: flex-start;
}

header h3 {
    margin: 0px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
}

.content p {
    margin: 0px;
    color: #737272;
    text-align: center;
}

.subscriptionOption {
    margin: 0px 15px;
}

.subscriptionOption2 {
    margin: 0px 15px;
}

.subscriptionOption div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 8px;
    gap: 12px;
    border-radius: 6px;
    margin: 20px 0px;
}

.subscriptionOption div p {
    margin: 0px;
}

.selectInput {
    margin: 0px 14px;
}

.actions2 {
    margin: 30px 14px;
}

.span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.help {
    display: flex;
    width: 50px;
    height: 50px;
    border: 1px #D7FBDB solid;
    border-radius: 50%;
    -webkit-animation: rotation 1s ease-in-out infinite;
    -moz-animation: rotation 1s ease-in-out infinite;
    animation: rotation 1s ease-in-out infinite;
    margin: 30px auto;
}

.help:after {
    width: 20px;
    height: 20px;
    background-color: #03C962;
    border-radius: 100%;
    position: absolute;
    content: "";
}

.subscriptionOption_list {
    background-color: #D7FBDB;
    border-radius: 6px;
    border: 2px solid var(--color-green-104, #03C962);
}

.subscriptionOption_list_normal {
    border-radius: 6px;
    border: 1px solid #BABABA;
    background: var(--color-grey-107, #F1F1F1);
}

.date_input {
    display: flex;
    justify-content: space-between;
}

.date_input .subscriptionOption2 {
    width: 100%;
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotation {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}