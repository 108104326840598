.body {
    display: flex;
    flex-direction: column;
    margin: 10px 15px;
}

.body h4 {
    margin: 20px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
}

.body__cancelButton {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.body__cancelButton__inner {
    width: 33.56px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000000;
    height: 33.56px;
}

.body__cancelButton__inner div {
    background-color: #000000;
    height: 1px;
    margin-top: 2px;
    width: 30px;
}

.bar1 {
    -webkit-transform: rotate(-135deg) translate(-10px, 7.9px);
    transform: rotate(-45deg) translate(-10px, 7.9px);
}

.bar2 {
    -webkit-transform: rotate(135deg) translate(5px, 3px);
    transform: rotate(45deg) translate(8px, 7px);
}

.heading {
    background-color: #FFDFD7;
    color: #FD4D1E;
    padding: 12px;
}

.heading h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 12px;
}

.heading h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 12px;
}

.heading div {
    display: flex;
    justify-content: space-between;
}

.desc h3 {
    color: #FD4D1E;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    margin: 12px 0px;
}

.desc h2 {
    font-weight: 700;
    font-size: 14px;
    margin: 12px 0px;
    line-height: 14px;
}

.desc p {
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
}

.buttons {
    display: flex;
    flex-direction: column;
}

.buttons a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
}

.warning {
    margin-top: 30px;
    font-weight: 500;
}