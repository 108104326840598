@media only screen and (max-width: 920px) {
  .subscription {
    display: flex;
    margin: 0px 8px;
    margin-bottom: 22px;
    flex-direction: column;
  }

  .logo {
    width: 25%;
  }

  .logo img {
    width: 100%;
  }

  .carType {
    display: flex;
    padding: 0px 10px;
  }

  .vehicleDetails {
    display: flex;
    flex-direction: column;
    background-color: #f3f3f3;
    padding: 12px;
    border-radius: 8px;
  }

  .vehicleDetails div {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .vehicleDetails div h4 {
    font-size: 20px;
  }

  .vehicleDetails div p {
    font-size: 12px;
  }

  .carType div p {
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
  }

  .subscription__body {
    display: flex;
    flex-direction: column;
  }

  .subscription__bodyInner {
    background-color: #ffffff;
  }

  .subscription__wrapper {
    display: flex;
    width: 100%;
  }

  .subscription__wrapper img {
    width: 100%;
    height: 100%;
  }

  .diagnose {
    margin: 6px 0px;
  }

  .diagnose h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: 18px 0px;
    color: #3c3e3f;
  }

  .diagnose p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #3c3e3f;
  }

  .diagnose h5 {
    margin: 18px 0px;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #3c3e3f;
  }

  .diagnose__wrapper {
    padding: 12px;
    background-color: #f3f3f3;
  }

  .diagnose__wrapper__inner {
    display: flex;
    margin: 5px 0px;
    justify-content: space-between;
  }

  .diagnose__wrapper__inner div p {
    padding: 12px 8px;
    overflow: hidden;
  }

  .error {
    background-color: white;
    overflow: hidden;
    display: flex;
    flex: 1;
    /* max-width: 80%; */
    width: 90%;
  }

  .error p {
    font-size: 12px;
  }

  .diagnose__wrapper__inner div a {
    text-decoration: none;
  }

  .diagnose__wrapper__inner div a p {
    background-color: #ffffff;
    color: #4fb128;
  }

  .wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    gap: 12px;
    margin: 7px 0px;
    background-color: #D7FBDB;
  }

  .wallet_ballance {
    margin: 10px 0px;
  }

  .wallet_ballance h3 {
    color: #168912;
    font-size: 23px;
    margin: 10px 0px;
  }

  .time {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .time p {
    margin: 10px 0px;
    font-size: 14px;
  }

  .onlineStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button {
    margin-top: 1.5rem;
  }
}