.progress-bar {
    display: flex;
    justify-content: space-between;
    margin: 0px 15px;
    z-index: -10;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 0.25rem;
    position: relative;
}

.step .step-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ccc;
    background-color: #fff;
    margin-bottom: 5px;
    transition: background-color 0.3s, border-color 0.3s;
}

.step .step-name {
    color: var(--Color-grey-green, #8EA396);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    display: flex;
    padding: var(--Field-sizes-Field---Label-gap, 6px) 4px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
}

.step-number {
    border-color: #03C962;
    background-color: #03C962;
    color: #D3DCE6;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8.8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.step.active .step-name {
    color: #03C962;

}

.step-number.completed {
    border-color: #03C962;
    background-color: #03C962;

    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 8.8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

/* .step::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 50%;
    height: 2px;
    width: 100%;
    background-color: #ccc;
    z-index: -1;
    transition: background-color 0.3s;
}

.step:first-child::before {
    content: none;
}

.step.active::before,
.step.completed::before {
    background-color: #03C962;
} */