.VehicleInfo {
    display: flex;
    flex-direction: column;
    margin: 0rem 0.9375rem;
}

.VehicleInfo_inner {
    display: flex;
    justify-content: space-between;
}

.VehicleInfo_inner div p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.VehicleInfo_inner div svg {
    margin: 0rem 0.5rem;
}

.vehicle_information {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: space-between;
    margin: 0rem 0rem;
}

.vehicle_information_info {
    display: flex;
    gap: 0.75rem;
    flex: 1;
    flex-direction: column;
}

.vehicle_information_info h1 {
    color: var(--Color-Dark-grey-100, #263238);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.vehicle_information_image {
    display: flex;
    width: 10.3125rem;
    height: 7.25rem;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    background: var(--color-grey-107, #F1F1F1);
}

.vehicle_information_image_wrapper {
    display: flex;
    width: 100%;
    height: auto;
}

.vehicle_information_image_wrapper img {
    width: 100%;
    height: auto;
    display: flex;
}

.vehicle_information_about {
    display: flex;
    width: 10.875rem;
    padding: 0.5rem 0.375rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    flex-shrink: 0;
    background: var(--color-grey-107, #F1F1F1);
    margin-left: 0.3125rem;
}

.vehicle_information_about_list {
    display: flex;
    padding: 0.25rem;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
    align-self: stretch;
    background: var(--color-white, #FFF);
}

.vehicle_information_about_list p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}