.body {
    margin: 0px 15px;
    margin-bottom: 80px;
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.maintenance_banner {
    display: flex;
    width: 100%;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    background: var(--color-light-red-100, #FFDFD7);
}

.maintenance_banner div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.maintenance_banner div h3 {
    color: var(--color-red-100, #FD4D1E);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.maintenance_banner div p {
    color: var(--color-dark-grey-100, #263238);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.header p {
    color: var(--color-dark-grey-1, #3C3E3F);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 4 B Left */
    font-family: Circular Std;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.image_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.image_wrapper_title {
    display: flex;
    width: 100%;
    padding: 12px 9px 2px 9px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.image_wrapper_title p {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    /* 100% */
}

.image_wrapper_inner {
    display: flex;
    width: 100%;
    height: 128px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.image_wrapper_inner_wrapper {
    display: flex;
    height: 128px;
    width: 100%;
}

.image_wrapper_inner_wrapper img {
    display: flex;
    height: auto;
    object-fit: contain;
    width: 100%;
}

.add_info {
    display: flex;
    width: 100%;
    height: 200px;
    overflow-y: scroll;
    padding-top: 12px;
    flex-direction: column;
    align-items: center;
}

.image_wrapper_title h2 {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    /* 214.286% */
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.buttons a {
    text-decoration: none;
}

.greenButton {
    background-color: #D7FBDB;
}

.greenButton button {
    background-color: #D7FBDB;
}