.buttons {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
}

.buttons button {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
}

.buttons a {
    text-decoration: none;
    margin-bottom: 20px;
}

.buttons a:nth-child(2) {
    margin-bottom: 0px;
}

.warning {
    font-weight: 500;
}

.warning p {
    margin: 0px;
    font-weight: 450;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 50px;
    text-align: center;
}


.contact {
    background-color: #D7FBDB;
    padding: 20px;
}

.footer {
    margin: 0px 15px;
}