.filter_head p {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #263238;
}

.head {
    display: flex;
    width: 100%;
    padding: 8px 12px 16px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 6px;
    background: var(--color-grey-107, #F1F1F1);
}

.filterCard {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    flex-wrap: wrap;
    border: 0.5px solid var(--color-grey-green, #8EA396);
    background: var(--mds-light-gohan, #FFF);
    width: 9.78125em;
}

.filterCard p {
    color: #999CA0;
    font-size: 12px;
}

.filterOptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}


@media only screen and (max-width: 360px) {
    .head {
        padding: 6px 10px 14px;
        gap: 8px;
        background: #F1F1F1;
    }

    .filterCard p {
        color: #999CA0;
        font-size: 10px;
    }
}

@media only screen and (max-width: 320px) {
    .head {
        padding: 6px 10px 14px;
        gap: 8px;
        background: #F1F1F1;
    }

    .filterCard p {
        color: #999CA0;
        font-size: 10px;
    }

    .filterCard {
        background: #FFFFFF;
        border-radius: 8px;
        padding: 8px;
        gap: 4px;
        width: 25%;
        display: flex;
        justify-content: space-between;
        margin: 8px 2px;
    }
}