.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000000;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.75);
}

.modal2 {
    position: relative;
    background-color: white;
    box-shadow: 0px 2px 10px rgba(141, 140, 140, 0.2);
    margin: auto;
    padding: 10px 0px;
    width: 327px;
    z-index: 10000000;
    overflow: hidden;
}

.header {
    background: white;
    padding: 1rem;
    color: black;
    margin: 0;
}

.content {
    padding: 1rem;
}

.actions {
    padding: 1rem;
    justify-content: center;
    flex-direction: column;
}

.actions div {
    justify-content: center;
}

.login__options {
    display: flex;
    flex-direction: column;
    margin: 15px 4px;
    align-items: flex-end;
}

.login__options .login__options_inner p {
    color: #3C3E3F;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 4px;
}

.login__options .login__options_inner a {
    text-decoration: none;
    font-weight: 700;
    color: #03C962;
    font-size: 14px;
    display: flex;
}

.form {
    margin: 0px 20px;
}

.login__options_inner {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: flex-start;
}