.head {
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
    align-items: center;
    gap: 65px;
    background-color: #F1F1F1;
}

.home_live_metrics {
    margin: 0px 15px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-bottom: 30px;
}

.head div h3 {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.head div h5 {
    color: var(--color-dark-grey-100, #263238);
    text-align: right;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.head div p {
    color: var(--color-dark-grey-100, #263238);
    text-align: right;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.home_live_metrics_inner {
    display: flex;
    flex-wrap: wrap;
    overflow-x: scroll;
    align-items: center;
    width: 100%;
    height: 300px;
    justify-content: space-between;
    gap: 12px 9px;
}

.metrics_card {
    display: flex;
    flex: 1;
    flex-basis: calc(28.33% - 9px);
    width: 108px;
    height: 60px;
    padding: 0px 8px;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    background: var(--color-green-02, #D7FBDB);
}

.metrics_card2 {
    flex: 1;
    flex-basis: calc(26.33% - 6px);
    display: flex;
    width: 108px;
    height: 100px;
    padding: 14px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    border-radius: 12px;
    background: var(--color-green-02, #D7FBDB);
}

.metrics_card2 div {
    width: 100%;
}

.metrics_card2 div h4 {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.metrics_card2 div p {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.metrics_card div h4 {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.metrics_card div p {
    color: var(--color-grey-green, #8EA396);
    font-family: Circular Std;
    font-size: 10px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.fuel_tank {
    width: 70px;
    height: 100px;
    display: flex;
    align-items: flex-end;
    position: relative;
    flex-basis: calc(26.33% - 15px);
    margin: 0.575rem 0.9375rem;
    border-radius: 12px;
    overflow: hidden;
}

.fuel_container {
    width: 108px;
}

.fuel_tank .inner_bar {
    border-radius: 4px;
    flex-grow: 1;
    height: 20%;
    width: 100%;
    transition: height 0.3s;
}

.fuel_metrics {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}

.fuel_metrics p {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.fuel_metrics p:nth-child(2) {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.papers_head {
    display: flex;
    width: 100%;
    padding: 0px 0px;
    gap: 12px 0px;
    justify-content: space-between;
    align-items: flex-end;
}

.papers_inner {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.papers_head div {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    /* flex: 1; */
    /* max-width: 50%; */
}

.papers_head div p {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
}

.papers_head div div {
    display: flex;
    align-items: center;
    gap: 2px;
}

.papers_head div div p {
    color: var(--Body-10-Center, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.papers {
    padding: 0px 15px;
}

.papers_card {
    display: flex;
    width: 102px;
    height: 102px;
    padding: 12px 14px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    border-radius: 6px;
    background: #cbf8c994;
    border: 1px solid #089D00;
    flex-shrink: 0;
    margin-right: 12px;
    flex-grow: 0;
    flex-basis: 102px;
}

.papers_card svg {
    width: 16px;
    height: 16px;
}

.papers_card p:nth-child(2) {
    color: #232323;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.papers_card p:nth-child(3) {
    color: #089D00;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.no_vin_papers {
    margin: 1.5rem 0px;
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background: var(--White, #FFF);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.12);
}

.no_vin_papers_header {
    display: flex;
    width: 100%;
    height: 2.5em;
    padding: 0.5rem 0.9375rem;
    align-items: center;
    gap: 4.0625rem;
    background: var(--Grey-100, #F3F3F3);
}

.no_vin_papers_card_wrapper {
    display: flex;
    padding: 0.75rem 0.9375rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    width: 100%;
}

.no_vin_papers_card {
    display: flex;
    width: 100%;
    height: 3.125em;
    padding: 0px 0.75rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0px 0px 0.25rem 0.25rem;
    background: #FFF3F0;
}

.no_vin_papers_card div {
    display: flex;
    height: 1.25em;
    gap: 0.5rem;
    padding-right: 0.125rem;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
}

.no_vin_papers_card div p {
    color: var(--Color-dark-grey-1, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body/ left 16 */
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.expired {
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    line-height: normal;
}

.no_vin_papers_btn {
    display: flex;
    width: 100%;
    height: 3em;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 6px;
    background: var(--Green-201, #03C962);
}

.no_vin_papers_btn a {
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Circular Std";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    width: 100%;
}