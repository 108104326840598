@media only screen and (max-width: 920px) {
    .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .actions button {
        cursor: pointer;
        color: white;
        background-color: #03C962;
        border: 1px solid #03C962;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        width: 100%;
        height: 44px;
        transition: all 0.2s ease-in-out;
        padding: 1.2rem, 1.6rem, 1.2rem, 1.6rem;
    }

    .actions button:hover {
        background-color: #03C962;
        border-color: #03C962;
        transition: all 0.2s ease-in-out;
    }

    .actions button:disabled {
        cursor: no-drop;
        background-color: #03C96268;
        border: 1px solid #03C96268;
        ;
        transition: all 0.2s ease-in-out;
    }

    .actions2 {
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .actions2 button {
        cursor: pointer;
        color: #03C962;
        background-color: rgb(255, 255, 255);
        border: 1px solid #03C962;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        width: 100%;
        height: 44px;
        transition: all 0.2s ease-in-out;
        padding: 1.2rem, 1.6rem, 1.2rem, 1.6rem;
    }

    /* .actions2 button:hover {
        background-color: #03C962;
        border-color: #03C962;
        transition: all 0.2s ease-in-out;
    } */

    /* .actions2 button:disabled {
        cursor: no-drop;
        background-color: #03C9624D;
        border: 1px solid #03C9624D;
        transition: all 0.2s ease-in-out;
    } */

    .actions3 {
        margin-bottom: 0.9rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .actions3 button {
        cursor: pointer;
        color: #03C962;
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        border-radius: 4px;
        width: 100%;
        height: 44px;
        transition: all 0.2s ease-in-out;
        padding: 1.2rem, 1.6rem, 1.2rem, 1.6rem;
    }

    .actions3 button svg {
        vertical-align: middle;
        margin-left: 12px;
    }
}