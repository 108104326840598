.head {
    margin: 0px;
    margin-top: 45px;
}

/* .body {
    height: 100vh;
} */

.main_hero {
    display: flex;
    flex-direction: column;
    margin: 0px 15px;
    margin-bottom: 80px;
}

.bottom_metrics {
    display: flex;
    justify-content: space-between;
    background: var(--color-green-02, #D7FBDB);
    padding: 8px 12px;
    align-items: center;
}

.bottom_metrics p {
    color: var(--color-green-104, #03C962);
    text-align: right;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.bottom_metrics_image_wrapper {
    width: 6.5em;
    height: 3em;
    display: flex;
}

.bottom_metrics_image {
    display: flex;
    width: 100%;
    height: auto;
}

.bottom_metrics_image img {
    width: 100%;
    height: auto;
    display: flex;
    object-fit: contain;
}

.metrics {
    position: fixed;
    width: 100%;
    /* background-color: white; */
    bottom: 75px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;

}

.metrics_list {
    display: flex;
    width: 4.3125em;
    height: 3.625em;
    padding: 0.5rem;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.375rem;
    /* background: #FFF; */
    /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.08); */
}

.metrics_list div {
    display: flex;
    gap: 0.125rem;
}

.metrics_list h4 {
    color: #263238;
    text-align: right;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.metrics_list span {
    font-size: 0.625rem;
}

.metrics_list p {
    color: #263238;
    font-family: Circular Std;
    font-size: 10px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.address {
    display: flex;
    flex-direction: column;
    padding: 20px 12px;
    width: 93%;
    border-radius: 0px 0px 12px 12px;
    background: var(--Grey-100, #F3F3F3);
}

.address div {
    display: flex;
    position: relative;


    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.address p {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.metrics_inner {
    display: flex;
    width: 93%;
    /* margin-bottom: 10px; */
    justify-content: space-between;
}

.metrics_inner_wrapper {
    display: flex;
    /* height: 111px; */
    padding: 8px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.780);
}

.timer {
    /* width: 40px; */
    /* height: 40px; */
    /* background-color: ; */
    color: #263238;
}

.dongle_card_wrapper {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 3.75rem;
    padding: 0.9375rem;
    padding-top: 32px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    flex-shrink: 0;
}

.dongle_card {
    display: flex;
    width: 100%;
    padding: 0.75rem 1.25rem 2.25rem 1.25rem;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    border-radius: 1.5rem;
    background: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.dongle_card_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.dongle_card_text h1 {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.dongle_card_text p {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 3 Left */
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px;
    /* 171.429% */
}

.dongle_card_btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
}

.dongle_card_btn div {
    display: flex;
    height: 3em;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    border-radius: 6px;
    background: #03C962;
}

.dongle_card_btn:nth-child(4) div {
    border-radius: 6px;
    border: 1px solid #03C962;
    background: #FFFF;
}

.dongle_card_btn div a {
    color: var(--Color-White, var(--White, #FFF));
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    text-decoration: none;
}

.dongle_card_btn:nth-child(4) div a {
    color: var(--P-green, #03C962);
}

.dongle_img_wrapper {
    display: flex;
    width: 221.25px;
    height: 175.5px;
}

.dongle_img_wrapper_inner {
    width: 100%;
    height: auto;
    display: flex;
}

.dongle_img_wrapper_inner img {
    width: 100%;
    height: auto;
    display: flex;
    object-fit: cover;
}

.map_container {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Set height to viewport height */
}

.map {
    width: 100%;
    height: 100%;
}