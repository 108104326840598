.header {
    display: flex;
    justify-content: flex-end;
}

.body {
    margin: 70px 15px;
    /* margin-top: 70px; */
}

.selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selection div {
    align-items: center;
    display: flex;
}

.selection div p {
    margin-left: 8px;
    user-select: none;
}

.body_inner {
    /* background-color: black; */
    display: flex;
    flex-direction: column;
}

.body_inner a {
    text-decoration: none;
}

.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
    gap: 0.75rem;
    width: 100%;
    height: 6.875em;
    background: #FFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    margin: 12px 0px;
}

.title {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
    flex: 1;
    max-width: 65%;
}

.title p:nth-child(1) {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 18px;
    color: #24B7A4;
}

.title p:nth-child(2) {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 18px;
    color: #4A4A4A;
}

.title p {
    margin-top: 4px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 0.75rem;
    line-height: 15px;

    /* Color/Dark grey 100 */

    color: #263238;
}

.time {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 20%;
    align-items: flex-end;
}

.time p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Color/Dark grey 100 */
    margin-bottom: 20px;
    color: #263238;
}

.time button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 8px;

    width: 4.0625em;
    height: 1.625em;

    /* Color/Green !02 */
    border: transparent;
    background: #D7FBDB;
    border-radius: 6px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 18px;
    color: #03C962;
}

.toggle {
    margin-left: 15px;
}

.page_body {
    margin: 0px 15px;
    margin-bottom: 80px;
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.page_header {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.maintenance_banner {
    display: flex;
    width: 100%;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    background: var(--color-light-red-100, #f1f0ef);
}

.maintenance_banner div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.maintenance_banner div h3 {
    color: var(--color-red-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.maintenance_banner div p {
    color: var(--color-dark-grey-100, #263238);
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.header p {
    color: var(--color-dark-grey-1, #3C3E3F);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 4 B Left */
    font-family: Circular Std;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.add_info {
    display: flex;
    width: 100%;
    height: 350px;
    overflow-y: scroll;
    padding-top: 12px;
    flex-direction: column;
    align-items: flex-start;
}

.image_wrapper_title h2 {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    /* 214.286% */
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.buttons a {
    text-decoration: none;
}

.greenButton {
    background-color: #D7FBDB;
}

.greenButton button {
    background-color: #D7FBDB;
}