@media only screen and (max-width: 920px) {
    .navBar {
        background-color: #FFFFFF;
        align-items: center;
        width: 100%;
        display: flex;
        position: fixed;
    }

    .navBar__body {
        display: flex;
        align-items: center;
        gap: 13px;
        padding: 12px 19px;
        width: 100%;
    }

    .navBar__hamburger {
        cursor: pointer;
        flex: 1;
        max-width: 15%;
    }

    .navBar__hamburger div {
        background-color: #3C3E3F;
        height: 2px;
        margin-top: 4px;
        width: 16px;
    }

    .navBar__title {
        display: flex;
        flex: 1;
        justify-content: center;
        max-width: 70%;
        align-items: center;
        text-align: center;
    }

    .navBar__title p {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
    }

    .navBar__notifications {
        display: flex;
        position: relative;
        justify-content: space-between;
    }

    .navBar__notifications svg {
        margin-left: 5px;
    }

    .newNotification {
        position: absolute;
        right: 6.25%;
        top: 0%;
        width: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12px;
        border-radius: 99px;
        bottom: 62.5%;
        background: #FC476E;
    }

    .notification {
        position: absolute;
        right: 6.25%;
        top: 0%;
        width: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12px;
        border-radius: 99px;
        bottom: 62.5%;
    }

    .notification p {
        font-style: normal;
        font-weight: 450;
        font-size: 9px;
        line-height: 100%;
        display: flex;
        align-items: center;
        color: #FFFFFF;
    }


    .online_bar .online_status p {
        color: #3C3E3F;
        text-align: right;
        font-family: Circular Std;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }

    .online_bar .online_status {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}