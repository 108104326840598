.carStatus {
    display: flex;
    flex-direction: column;
    background-color: #E7E7E7;
    margin: 30px 0px;
    padding: 10px 10px;
}

.carStatus__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
}

.carStatus__inner2 {
    display: flex;
    background-color: white;
    height: 100%;
    justify-content: space-between;
}

.carStatus__inner2 p {
    display: flex;
    align-items: center;
    margin: 0px;
}

.carStatus__inner2 p span {
    margin-right: 4px;
}

.carStatus__inner h4 {
    font-weight: 700;
    font-size: 16px;
    margin: 0px;
    line-height: 20px;
    color: #263238;
}

.carStatus__inner2__status {
    color: #008069;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    max-width: 20%;
    align-items: center;
    background-color: #83C91D0D;
    padding: 10px 20px;
    text-align: center;
    flex: 1;
}

.carStatus__inner2__title {
    color: #263238;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #8EA396;
    padding: 10px 20px;
    flex: 1;
    max-width: 70%;
    background-color: #ffffff;
}

.carStatus__inner2__title span svg {
    margin-right: 11px;
}