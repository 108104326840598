@media only screen and (max-width: 920px) {
    .buyDongle {
        display: flex;
        flex-direction: column;
        /* background-color: #FAFAFA; */
    }

    .banner {
        width: 100%;
        margin: 0px 0px;
        background: #FFF3F0;
        margin-top: 10px;
        box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.13);
    }

    .banner p {
        color: #FD4D1E;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .logo {
        width: 10%;
    }

    .logo img {
        width: 100%;
    }

    .carType {
        display: flex;
        margin: auto;
        width: 256px;
        height: auto;
    }

    .vehicleDetails {
        display: flex;
        flex-direction: column;
        padding: 12px;
        border-radius: 8px;
    }

    .vehicleDetails div {
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
    }

    .home__liveMetrics {
        margin: 12px 0px;
        background-color: #FFFFFF;
        padding: 5px;
    }

    .home__liveMetrics p {
        margin-bottom: 12px;
    }

    .home__liveMetrics__inner {
        align-items: center;
        background-color: #FAFAFA;
        border-radius: 8px;
        padding: 15px 10px;
        display: flex;
        justify-content: space-between;
    }

    .home__liveMetrics__inner div p {
        font-weight: 450;
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 18px;
    }

    .home__liveMetrics__inner div div span {
        margin-left: 5px;
    }

    .vehicleDetails div h4 {
        font-size: 20px;
    }

    .vehicleDetails div p {
        font-size: 12px;
    }

    .carType div p {
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
    }

    .online_status {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .buyDongle__body {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        gap: 32px;
    }

    .buyDongle__bodyInner {
        background-color: #FFFFFF;
        margin: 0px 16px;
    }

    .home_unregistered_vehicles {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .buyDongle__greetings p {
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;
        margin-left: 8px;
    }

    .buyDongle__wrapper {
        display: flex;
        width: 100%;
        height: auto;
    }

    .buyDongle__wrapper img {
        width: 100%;
        margin: auto;
        height: auto;
        object-fit: cover;
    }

    .request_for_vehicle_image {
        display: flex;
        margin: 12px auto;
        align-self: center;

    }

    .buyDongle__order {
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
        padding: 22px 16px;
        background-color: #87E88226;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
    }

    .buyDongle__order .buyDongle__order__text {
        justify-content: center;
        color: #3C3E3F;
        flex: 1;
        margin-right: 12px;
        max-width: 50%;
        margin-right: 42px;
        align-items: center;
        flex-direction: column;
    }

    .buyDongle__order .buyDongle__order__text h3 {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 8px;
    }

    .buyDongle__order .buyDongle__order__text p {
        font-weight: 450;
        font-size: 11px;
    }

    .buyDongle__order__button {
        width: 40%;
    }

    .buyDongle__order2 {
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
        padding: 22px 16px;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
    }

    .buyDongle__order2 .buyDongle__order__text {
        justify-content: center;
        color: #3C3E3F;
        flex: 1;
        margin-right: 12px;
        max-width: 50%;
        margin-right: 42px;
        align-items: center;
        flex-direction: column;
    }

    .buyDongle__order2 .buyDongle__order__text h3 {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 8px;
    }

    .buyDongle__order2 .buyDongle__order__text p {
        font-weight: 450;
        font-size: 11px;
    }

    .buyDongle__order__button {
        width: 40%;
    }

    .buyDongle__features {
        margin: 12px 12px;
        background-color: #FAFAFA;
    }

    .posterWrapper {
        width: 50%;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .buyDongle__more__text {
        display: flex;
        padding: 12px;
        flex-direction: column;
        width: 43%;
        /* background-color: red; */
    }

    .buyDongle__more__text h4 {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .buyDongle__more__text p {
        font-weight: 450;
        color: #3C3E3F;
        font-size: 12px;
        line-height: 15px;
    }

    .posterWrapper img {
        width: 100%;
    }

    .buyDongle__more {
        display: flex;
    }

    .buyDongle__more__text div {
        margin-top: 60px;
        display: flex;
        justify-content: flex-end;
    }

    .buyDongle__more__text div p {
        color: #03C962;
    }

    .video {
        background-color: #03C962;
        display: flex;
    }

    .videoWrapper {
        margin: auto;
        padding: 17px 39px;
        width: 70%;
        /* width: 90%; */
        position: relative;
        /* display: flex; */
        /* flex-direction: column; */
    }

    .videoWrapper img {
        width: 100%;
    }

    .videoWrapper video {
        width: 100%;
    }

    .videoWrapper svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    .videoWrapper p {
        position: absolute;
        top: 80%;
        left: 20%;
        color: white;
        font-size: 12px;
    }

    .liveMetrics {
        margin: 0px 5px;
    }

    .liveMetrics_heading {
        margin: 15px 0px;
    }

    .liveMetrics_heading p {
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
    }

    .liveMetrics_info {
        background: rgba(135, 232, 130, 0.15);
        border-radius: 6px;
        align-items: center;
        padding: 16px 5px;
        display: flex;
        justify-content: space-between;
    }

    .liveMetrics_info h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 5px;
        color: #168912;
    }

    .liveMetrics_info p {
        font-weight: 450;
        font-size: 12px;
        line-height: 13px;
        color: #263238;
    }

    .vehicleDetails_2 div {
        padding: 10px 0px;
        background: #F1F1F1;
        margin-top: 5px;
        margin-bottom: 24px;
    }

    .vehicleDetails_2 p {
        gap: 10;
        margin: 10px 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #263238;
    }

    .carStatus {
        display: flex;
        flex-direction: column;
        background-color: #F7F7F7;
        margin: 30px 5px;
        padding: 10px 10px;
    }

    .carStatus__inner {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        align-items: center;
    }

    .carStatus__inner2 {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        height: 100%;
        justify-content: space-between;
    }

    .carStatus__inner2 p {
        display: flex;
        align-items: center;
    }

    .carStatus__inner2 p span {
        margin-right: 4px;
    }

    .carStatus__inner h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #263238;
    }

    .carStatus__inner2__title {
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 20px;
        flex: 1;
        max-width: 70%;
        display: flex;
        background-color: #ffffff;
        color: #263238;
    }

    .carStatus__inner2__title__head {
        font-weight: 450;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 20px;
        flex: 1;
        max-width: 70%;
        flex-direction: column;
        display: flex;
        background-color: #ffffff;
        color: #263238;
    }

    .carStatus__inner2__status__head {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        width: 30%;
        height: 100%;
        align-items: center;
        background-color: #FFF5CC;
        padding: 10px 0px;
        position: relative;
        color: #FF9900;
    }

    .carStatus__inner2__status {
        font-weight: 500;
        position: relative;
        font-size: 14px;
        line-height: 18px;
        width: 30%;
        display: flex;
        height: 100%;
        align-items: center;
        background-color: #FFF5CC;
        padding: 10px 20px;
        text-align: center;
        color: #FF9900;
    }

    .maintanance {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        margin: 24px 16px;
    }

    .maintanance div {
        width: 100%;
    }

    .maintanance div h2 {
        color: var(--color-dark-grey-100, #263238);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Circular Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .maintanance_card_cover {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        overflow-y: auto;
        height: 500px;
    }

    .maintanance_card {
        display: flex;
        height: 6.3125em;
        padding: 0.75em;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.6875rem;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 0.75rem;
        background: #F1F1F1;
    }

    .maintanance_card_info {
        display: flex;
        height: 53px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex-shrink: 0;
        align-self: stretch;
    }

    .maintanance_card_info p:nth-child(1) {
        color: var(--Color-dark-grey-1, #3C3E3F);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .maintanance_card_info p:nth-child(2) {
        color: var(--Color-dark-grey-1, #3C3E3F);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }



    .maintanance_card_action p,
    .maintanance_card_action a {
        text-decoration: none;
        color: var(--Color-Green-104, #008069);
        text-align: right;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Body/ Right 10 */
        font-family: "Circular Std";
        font-size: 10px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }

    .products {
        padding: 0px 1rem;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 4.6875rem;
    }

    .products_head_text p {
        color: #263238;
        font-size: 14px;
    }

    .home_marketting {
        display: flex;
        gap: 2rem;
        width: 100%;
        overflow-x: auto;
    }

    .home_marketting_inner {
        display: flex;
        width: 305px;
        flex-shrink: 0;
        flex-direction: row-reverse;
        height: 140px;
        border-radius: 0.5rem;
        background-color: rgba(128, 128, 128, 0.24);
    }

    /* .home_marketting_inner:nth-child(2) .home_marketting_inner_text p {
        color: #03C962;
    } */

    .home_marketting_inner:nth-child(2) .home_marketting_inner_text p:nth-child(3) {
        color: #263238;
        font-size: 12px;
        font-weight: 450;
        opacity: 0.6;
    }

    .home_marketting_inner_text {
        height: auto;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 60%;
        padding: 1.5625rem 1.25rem;
        gap: 0.375rem;
    }

    .home_marketting_inner_text p {
        color: rgba(0, 0, 0, 1);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .home_marketting_inner_text p:nth-child(3) {
        font-size: 12px;
        font-weight: 450;
    }

    .home_marketting_inner_text h1 {
        color: var(--Color-Dark-grey-100, #263238);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 8px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }

    .home_marketting_inner_text a {
        width: 120px;
        height: 28px;
        display: flex;
        align-items: center;
        padding: 7px 23px 6px 23px;
        text-decoration: none;
        gap: 0px;
        border-radius: 4px;
        color: white;
        background: rgba(29, 129, 45, 0.98) !important;

    }


    .home_marketting_inner_text a p {
        color: white;
        display: flex;
        margin: auto;
    }


    .home_marketting_inner_image {
        width: 325px;
        display: flex;
        height: auto;
        height: 140px;
        border-radius: 0px 10px 10px 0px;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
    }

    .home_marketting_inner_image_wrapper {
        display: flex;
        width: 100%;
        height: auto;
    }

    .home_marketting_inner_image_wrapper img {
        width: 100%;
        height: auto;
        border-radius: 0px 10px 10px 0px;
        display: flex;
        object-fit: cover;
    }

    .suggestions {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.75rem;
        padding: 0.5px 1rem;
    }

    .suggestions p {
        color: #686868;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .suggestions_inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        /* overflow-x: auto; */
        gap: 1.5rem;
    }

    .suggestions_card {
        height: 11.25em;
        display: flex;
        flex-direction: column;
        width: 6.375em;
    }

    .suggestions_card_image {
        display: flex;
        width: 6.375em;
        height: 7.6875em;
    }

    .suggestions_card a {
        text-decoration: none;
        width: 100%;
        gap: 0.75rem;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .suggestions_card_image_inner {
        display: flex;
        height: auto;
        width: 100%;
    }

    .suggestions_card_image_inner img {
        width: 100%;
        height: auto;
        display: flex;
    }

    .suggestions_card_text {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .suggestions_card_text h1 {
        color: var(--Color-Dark-grey-100, #263238);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .suggestions_card_text p {
        color: #686868;
        text-align: start;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Circular Std";
        font-size: 10px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
    }
}