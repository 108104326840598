.loading-animation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.loading-animation-row {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}

.loading-animation-card {
    width: 100px;
    height: 120px;
    margin: 0 10px;
    background-color: rgb(210, 210, 210);
    border-radius: 5px;
    animation: pulse 1.5s infinite;
}

.loading-animation-card-1 {
    width: 350px;
    height: 120px;
    margin: 0 10px;
    background-color: rgb(210, 210, 210);
    border-radius: 5px;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
    }
}