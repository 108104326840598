.transactionList_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0px;
}

.transactionList_inner div p {
    margin: 0px;
}

.transactionList_inner div:nth-child(2) {
    /* background-color: red; */
    margin: 0px 0px;
    width: 45%;
}

.transactionList_inner div h3 {
    margin: 0px;
}

.transactionList_icon {
    background: #D7FBDB;
    border-radius: 12px;
    padding: 16px;
    gap: 10px;
}

.transactionList_inner div h3 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #2C3A4B;
}

.transactionList_inner div p {
    font-weight: 450;
    font-size: 12px;
    line-height: 28px;
    color: #8EA396;
}

.transactionList_amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.transactionList_amount h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #2C3A4B;
    margin-right: 32px;
}