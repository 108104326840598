.carStatus {
    display: flex;
    flex-direction: column;
    background-color: #E7E7E7;
    margin: 30px 0px;
    padding: 10px 10px;
}

.carStatus2 {
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
}

.carStatus__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
}

.carStatus__inner2 {
    display: flex;
    background-color: white;
    height: 100%;
    justify-content: space-between;
}

.carStatus__inner2 p {
    display: flex;
    align-items: center;
    margin: 0px;
}

.carStatus__inner2 p span {
    margin-right: 4px;
}

.carStatus__inner h4 {
    font-weight: 700;
    font-size: 16px;
    margin: 0px;
    line-height: 20px;
    color: #263238;
}

.carStatus__inner2__status {
    color: #008069;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    max-width: 35%;
    align-items: center;
    background-color: #E2FBD7;
    padding: 10px 20px;
    text-align: center;
    flex: 1;
}

.carStatus__inner2__title {
    color: #263238;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 20px;
    flex: 1;
    max-width: 70%;
    background-color: #ffffff;
}

.carStatus__inner2__title span svg {
    margin-right: 11px;
    vertical-align: middle;
}

.metrics_card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.metrics_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #F5FFF1;
    align-items: center;
}

.metrics_header p {
    margin: 10px 12px;
    font-size: 1rem;
    color: #3C3E3F;
}

.metrics_header p:nth-child(2) {
    font-size: 1.125rem;
    color: #34B53A;
}

.metrics_content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #D7FBDB;
    align-items: center;
}

.metrics_content div {
    margin: 10px 12px;
}

.metrics_content_inner p {
    margin: 0px;
}

.metrics_content_inner svg {
    margin-right: 11px;
}

.metrics_content p {
    margin: 0px;
    color: #3C3E3F;
}

.metrics_content_inner {
    align-items: center;
    display: flex;
}

.metrics_content div:nth-child(2) p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5625rem;
    display: flex;
    align-items: center;
    text-align: right;
    color: #008069;
}