.head {
    margin: 60px 0px;
    margin-bottom: 100px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin: 0px 15px;
    margin-top: 50px;
    /* margin-bottom: 50px; */
}

.nav {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    gap: 1px;
    /* justify-content: center; */
    align-items: center;

    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), #D7FBDB; */
}

.nav a {
    border: 1px solid var(--color-green-104, #03C962);
    flex: 1;
    display: flex;
    text-decoration: none;
}

.nav a div {
    padding: 8px 8px;
    gap: 8px;
    max-width: 100%;
    flex: 1;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-left: 2px;
}

.nav a div p {
    color: #03C962;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

.nav a .nav_insurance {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
}

.nav .active {
    /* gap: 8px; */
    /* padding: 8px 8px; */
    background: var(--color-green-104, #03C962) !important;
    background-color: #FFFFFF;
}

.nav .active div p {
    color: #FFF;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.register_header {
    /* margin-top: 150px; */
    text-align: center;
}

.register_header h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: #3C3E3F;
    margin-bottom: 8px;
}

.register_header p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #3C3E3F;
}

.input {
    display: none;
}

.imageUploadWrapper {
    background-color: #F7F7F7;
    /* text-align: center; */
    width: 100%;
    margin: 10px 0px;
}

.imageUploadWrapper label {
    margin: 97px 0px;
}

.imageUploadWrapper label svg {
    margin-bottom: 12px;
}

.imageUploadWrapper label p {
    color: #4A4A4A;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.videoUpload {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    align-items: flex-end;
}

.videoUpload label {
    display: flex;
    align-items: center;
}

.videoUpload label p {
    margin-right: 6px;
    font-weight: 700;
    font-size: 14px;
    color: #4A4A4A;
    line-height: 20px;
}

.videoUpload {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
}


.videoUpload p {

    font-weight: 700;
    font-size: 14px;

    height: auto;
    /* background-color: blue; */
    color: #4A4A4A;
    line-height: 20px;
}

.uploadedImageWrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    width: 100%;
}

.uploadedImageWrapper_container {
    display: flex;
    height: 100px;
    flex: 1;
    min-width: 150px;
}

.uploadedImageWrapper_inner {
    display: flex;
    height: auto;
    width: 100%;
}

.uploadedImageWrapper_inner img {
    display: flex;
    height: auto;
    width: 100%;
}

.plateNumber_input {
    margin-bottom: 48px;
}

.register_head p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #3C3E3F;
}

.register_head {
    margin-top: 50px;
    /* margin-bottom: 30px; */
}

.buyDongle__wrapper {
    display: flex;
    width: 100%;
    margin: 0px;
    /* opacity: 0.3; */
    position: relative;
    /* transition: background-color 0.5s; */
}


.buyDongle__wrapper img {
    width: 90%;
    margin: auto;
    height: 100%;
}

.claims_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
    margin-bottom: 5.625rem;
    padding: 0.5px 1rem;
}

.body {
    display: flex;
    width: 100%;
    padding: 12px 15px;
    align-items: center;
    gap: 12px;
    background: #D7FBDB;
}

.details {
    margin: 0px 15px;
}

.body p {
    flex: 1;
    gap: 8px;
    display: flex;
    align-items: center;
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.body p span {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.insurance_packages {
    width: 100%;
    margin-bottom: 80px;
}

.insurance_packages_header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 18px 12px;
    gap: 8px;
    margin-bottom: 20px;
    background: #F1F1F1;
}

.insurance_packages_header p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #263238;
}

.carousel_inner {
    margin: 0px 10px;
    border-bottom: 0.3px solid #05050577;
    /* box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.17); */

}

.carousel_inner_header {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: 40px 40px; */
    text-align: center;
}

.carousel_inner_header h4 {
    margin-top: 30px;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 6px;
}

.additionalInfo {
    padding: 12px 9px;
}

.additionalInfo p {
    color: #797979;
    margin: 11px 0px;
    font-size: 12px;
}

.carousel_inner_header h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
}

.carousel_inner_header p {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
}

.insurance_packages_inner {
    width: 100%;
    overflow-x: scroll;
}

.cardButton {
    display: flex;
    flex-direction: column;
    margin-top: 46px;
    background-color: white;
}

.cardButton button {
    margin: auto;
    color: white;
    padding: 10px 50px;
    gap: 10px;
    border-radius: 6px;
    font-size: 14px;
    border: 1px solid transparent;
}

.cardButton button:disabled {
    opacity: 0.8;
}

.footer {
    display: flex;
    background: #D7FBDB;
    justify-content: space-between;
    align-items: center;
    margin: 20px 15px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 21px 16px;
    gap: 44px;
}

.footer_inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 70%;
    color: #263238;
}

.footer_inner p {
    font-size: 10px;
}

.footer_inner h3 {
    font-size: 16px;
    margin-bottom: 8px;
}

.footer_button {
    width: 30%;
    align-items: center;
    margin-top: 10px;
}