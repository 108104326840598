@media only screen and (max-width: 920px) {
    .head {
        width: 100%;
    }

    .liveMetrics__inner_body {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
    }

    .liveMetrics__inner_body_content {
        display: flex;
        margin: 5px 0px;
        justify-content: space-between;
    }

    .liveMetrics__inner_body_content div {
        width: 90%;
    }


    .liveMetrics__inner_body_content_body {
        padding: 8px 8px;
        height: 80px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 128, 105, 0.2);
        border-radius: 6px;
        margin-left: 3px;
        margin-bottom: 2px;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }

    .liveMetrics__inner_body_content_body h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
        margin-top: 8px;
        text-align: center;
    }

    .liveMetrics__inner_body_content_body p {
        font-weight: 450;
        font-size: 10px;
        line-height: 13px;
        color: #3C3E3F;
        display: flex;
        justify-content: center;
    }

    .fuelGuage_scale svg {
        z-index: 1000;
        position: absolute;
        top: 50%;
        left: 48%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    .drivingData {
        display: flex;
        padding: 10px 10px;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 10px;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        /* width: 40%; */
        background-color: #E2FBD7;
    }

    .drivingData p {
        margin-left: 12px;
        font-size: 14px;
    }
}