.history {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 1.5625rem;
}

.history a {
    text-decoration: none;
}

.head {
    margin: 0px 15px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 85px;
}

.history_list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
}

.list_wrapper {
    height: 50vh;
    overflow: auto;
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
}

.list {
    display: flex;
    width: 100%;
    padding: 16px 15px;
    align-items: center;
    gap: 12px;
    border-radius: 6px;
    background: #FFF;
}

.list div {
    flex: 1;
    flex-direction: column;
    display: flex;
    width: 9.5em;
    margin: 10px 5px;
}

.list div:nth-child(2) {
    align-items: flex-end;
}

.list div p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #8EA396;
}

.list div h5 {
    font-weight: 700;
    font-size: 14px;
    color: #4A4A4A;
    line-height: 18px;
}

.list div .title {
    color: #EF5DA8;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form div .input {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
}

.list_info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.calender_filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
}

.calender_filter div {
    flex: 1;
}

.filterButton {
    width: 100%;
}

.filterButton div svg {
    margin: 0px 9px;
}