@media only screen and (max-width: 920px) {
    .myCar {
        display: flex;
        flex-direction: column;
        background-color: #FAFAFA;
    }

    .myCar__body {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        padding: 5px 15px;
        margin-bottom: 50px;
    }

    .map__wrapper {
        width: 100%;
        position: relative;
    }

    .map__wrapper img {
        width: 100%;
        height: 100%;
        display: flex;
    }

    .myCar__location {
        background-color: white;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 10px 10px 6px;
        gap: 10px;
        position: absolute;
        top: 15%;
        left: 31%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    .myCar__location h4 {
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        color: #3C3E3F;
    }

    .myCar__location p {
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
        color: #BABABA;
    }

    .myCar__body__dashboard {
        /* display: flex; */
        margin-top: 12px;
        justify-content: space-between;
        background-color: rgb(245, 245, 245);
        /* background-color: aqua; */
    }

    .myCar__body__dashboard__menu {
        padding: 4px 8px;
        background-color: #FFFFFF;
        /* background-color: red; */
        display: flex;
        align-items: center;
    }

    .myCar__body__dashboard__menu div {
        align-items: center;
        /* background-color: blue; */
        /* border-bottom: 1px solid #D9D9D9; */
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 6px;
    }

    .myCar__body__dashboard__menu .head {
        margin-bottom: 25px;
    }

    .myCar__body__dashboard__menu .head p {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 12px;
        line-height: 15px;
        color: #3C3E3F;
    }

    .myCar__body__dashboard__menu div a {
        text-decoration: none;
    }

    .myCar__body__dashboard__menu div a p {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 23px;
        line-height: 15px;
        color: #BABABA;
    }

    .myCar__body__dashboard__menu a {
        text-decoration: none;
    }

    .myCar__body__dashboard__menu a p {
        font-weight: 700;
        font-size: 13px;
        margin-bottom: 23px;
        line-height: 15px;
        color: #BABABA;
    }


    .myCar__body__dashboard__menu div .active p {
        /* background-color: #87E88226; */
        padding: 2px 2px;
        margin-left: 1px;
        color: #4FB128;
    }

    .outlet {
        background-color: #FAFAFA;
        /* display: flex; */
        /* flex: 1; */
        margin-left: 8px;
    }
}