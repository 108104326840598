.head {
    margin: 0px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 115px;

}

.form {
    display: flex;
    flex-direction: column;
    /* height: 305px; */
    gap: 2px;
    /* overflow-y: scroll; */
}

.claim_form {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.form_clause {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    align-self: stretch;
}

.form_clause h1 {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 3 B Left */
    font-family: "Circular Std";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.form_clause p {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 2 Left */
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 26px;
    /* 162.5% */
}

.form__inner label {
    color: #3C3E3F;
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    /* margin-bottom: 0.1rem; */
}

.selected {
    border: 1px solid #03C962;
}

.head_title_banner {
    display: flex;
    padding: 8px 15px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: var(--Color-grey-107, #F1F1F1);
}

.head_title h1 {
    color: var(--Text, #201E1E);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    /* 29px */
}

.buyDongle {
    display: flex;
    flex-direction: column;
}

.buyDongle__body {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 90px;
    gap: 24px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin: 0px 0px;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    gap: 15px;
}

.cartNav {
    display: flex;
    align-items: center;
    position: relative;
    z-index: -1;
    border-bottom: 5px solid #D7FBDB;
    justify-content: space-between;
    margin: 0px;
}

.main_hero .cartNav p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8EA396;
    width: 33%;
    display: flex;
    justify-content: center;
    padding: 0px;
    padding-bottom: 10px;
    position: relative;
    margin: 0px;
}

.main_hero p {
    font-size: 14px;
    color: #393F42;
}

.carType {
    display: flex;
    padding: 5px 10px;
    height: 7.5em;
    width: 12.5em;
    align-self: center;
}

.carType .buyDongle__wrapper {
    display: flex;
    width: 100%;
    height: auto;
}

.buyDongle__wrapper img {
    width: 100%;
    display: flex;
    margin: auto;
    height: auto;
}

.vehicleDetails {
    display: flex;
    flex-direction: column;
    background-color: #F3F3F3;
    padding: 12px;
    color: black;
    border-radius: 8px;
}

.vehicleDetails div {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
}

.vehicleDetails div h4 {
    font-size: 20px;
}

.vehicleDetails div p {
    font-size: 12px;
    color: black;
}

.main_hero p {
    font-weight: 450;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    text-align: center;
    text-transform: capitalize;

    /* Color/dark grey 1 */

    color: #3C3E3F;
}

.vehiclePapers {
    border-radius: 14px;
    padding: 20px 15px;
    gap: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.insurancePapers {
    display: flex;
    align-items: center;
    gap: 64px;
    border-radius: 14px;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.insurancePapers h4 {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.insurancePapers p {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body 4 Centre */
    font-family: "Circular Std";
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.confirmStatus {
    padding: 4px 16px;
    gap: 8px;
    background: #D7FBDB;
    border-radius: 6px;
}

.vehiclePapers2 {
    background: linear-gradient(186.9deg, #03C962 26.79%, #35D853 113.67%);
    /* invisible stroke */

    border: 1px solid #F3F4F4;
    /* small w drop shadow */

    box-shadow: 0px 2px 10px rgba(215, 215, 215, 0.2);
    border-radius: 14px;
}


.modal {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal p {
    padding: 4px 16px;
    gap: 8px;
    background: #E2FBD7;
    border-radius: 6px;
    width: 30%;
    margin-top: 12px;
}

.confirmStatus p {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #088457;
}

.confirmStatus p a {
    color: #088457;
}

.vehiclePapers .papers_title {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.header_date {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.header_date p {
    width: 100%;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;

    color: #9B9B9B;
    text-align: start;
}

.header_date h5 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    color: #4A4A4A;
}

.order_details_personal_info {
    margin-bottom: 10px;
    border-bottom: 15px solid #F0F2F580;
    padding: 4px 10px;

}

.deliveryHead {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
}

.location {
    display: flex;
}

.location p {
    margin-left: 5px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* Color/Dark grey 100 */

    color: #263238;
}

.deliveryHead p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */


    color: #393F42;
}

.vehiclePapers div {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: flex-end;
    justify-content: space-between;
}

.vehiclPapers_card_price {
    display: flex;
    align-items: flex-end;
    width: 30%;
    flex-direction: column;
}

.vehiclPapers_card_price h3 {
    margin-bottom: 10px;
}

.vehiclePapers div h4 {
    margin-bottom: 10px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #263238;

}

.vehiclePapers div p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;

    /* Color/Dark grey 100 */

    color: #263238;
}

.button {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px;
}

.button_inner {
    display: flex;
    justify-content: space-around;
    padding: 12px, 16px, 12px, 16px;
}

.button_inner h4 {
    font-size: 16px;
}

.form {
    display: flex;
    flex-direction: column;
}

.form p {
    margin-bottom: 12px;
}

.form .otpWrapper input {
    width: 100%;
    font-size: 24px;
    padding: 3px;
    border: none;
    color: #4A4A4A;
    border-bottom: 2px solid #4A4A4A;
}

.form .otpWrapper input:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #03C962;
    background-color: #FBFFFE;
}

.form .otpWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 0px;
    background: #FFFFFF;
    border: 1px solid #BABABA;
    border-radius: 6px;
}

.form__inner {
    display: flex;
    flex-direction: column;
}


.form__passwordInput {
    align-items: center;
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
}



.form__inner select {
    width: 100%;
    font-size: 16px;
    border: 1px solid #BABABA;
    border-radius: 6px;
    padding: 13px 12px;
    background-color: white;
    color: black;
    -webkit-appearance: none;
    appearance: none;
}

.form__inner select:focus {
    outline: none;
    border: 1px solid #03C9624D;
    background-color: #FBFFFE;
}

.form__inner .invalid {
    color: #FD4D1E;
}

.form__inner .invalidInput {
    background-color: #FFFBFB;
    border: 1px solid #FD4D1E;
    color: #FD4D1E;
}

.form__inner .form__passwordInput .invalidInput {
    background-color: #FFFBFB;
    border: 1px solid #FD4D1E;
    color: #FD4D1E;
}

.total {
    margin: 0px 0px;
    margin-top: 20px;
    background-color: #F1F1F1;
}

.total div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.total div p {
    margin: 0px 8px;
    font-size: 16px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    line-height: 26px;
    /* identical to box height, or 162% */


    color: #393F42;
}

.total div h4 {
    margin: 0px 8px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: right;

    color: #393F42;
}

.grandTotal {
    background: #D7FBDB;
    padding: 4px 2px;
    display: flex;
    align-items: center;
}

.grandTotal p {
    margin: 12px 8px;
    font-size: 16px;
}

.grandTotal h4 {
    font-size: 18px !important;
    margin: 0px 8px;
}

.grandDiscount {
    background: #F1F1F1;
    padding: 4px 2px;
    display: flex;
    align-items: center;
}

.grandDiscount p {
    margin: 12px 0px;
}

.grandDiscount h4 {
    font-size: 18px;
}

.button button {
    cursor: pointer;
    color: white;
    border: 1px solid #D7FBDB;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    width: 100%;
    height: 44px;
    transition: all 0.2s ease-in-out;
    padding: 1.2rem, 1.6rem, 1.2rem, 1.6rem;
}

.button2 {
    margin: 20px 0px;
    margin-bottom: 90px;
}


.button2 a {
    text-decoration: none;
}

.button button:disabled {
    cursor: no-drop;
    background-color: #03C9624D;
    border: 1px solid #03C9624D;
    transition: all 0.2s ease-in-out;
}

.button3 {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}


.button3 a {
    text-decoration: none;
}

.button button:disabled {
    cursor: no-drop;
    background-color: #03C9624D;
    border: 1px solid #03C9624D;
    transition: all 0.2s ease-in-out;
}

.header {
    border-bottom: 15px solid #F0F2F580;
    padding: 4px 10px;

    margin: 6px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delivery_header {
    margin: 6px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.delivery_header span {
    display: flex;
    align-items: center;
}

.header div {
    display: flex;
    align-items: center;
}

.header div svg {
    margin-right: 15px;
}

.header div h4 {
    margin-right: 12px;
}

.header span {
    display: flex;
    align-items: center;
}

.header span svg {
    margin-right: 0.625rem;
}

.bankDetails {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-bottom: 15px solid #F0F2F580;
    padding: 4px 10px;

}

.bankDetails h3 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: left;
    color: #263238;
}

.bankDetails h2 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    color: #263238;
}

.imageWrapper {
    width: 100%;
}

.imageWrapper img {
    width: 100%;
}

.card {
    margin: 15px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.card svg {
    margin-bottom: 20px;
}

.card div h4 {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 14px;
    color: #3C3E3F;
}

.card div a {
    text-decoration: none;
}

.card div p {
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 14px;
    color: #3C3E3F;
}

.orderStatus {
    border-radius: 16px;
    padding: 8px 15px;
    gap: 8px;
}

.orderStatus p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
}

.transaction_notifications {
    text-align: center;
    border-bottom: 15px solid #F0F2F580;
    padding: 4px 10px;

    gap: 0.3125rem;
}

.transaction_notifications div {
    display: flex;
    justify-content: space-between;
}

.transaction_notifications p {
    color: #263238;
    font-size: 14px;
}

.date {
    margin: 19px 0px
}

.date p {
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    color: #9B9B9B;
}


.total2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.total2 div {
    display: flex;
    justify-content: space-between;
}

.total2 div p {
    margin: 0px 8px;
    font-size: 16px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    line-height: 26px;
    color: #393F42;
}

.total2 div h4 {
    margin: 0px 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: right;

    color: #393F42;
}

.contact_cus {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 0px;
    gap: 8px;
    margin-top: 20px;
    width: 100%;
    background: #F1F1F1;
    border-radius: 6px;
}

.contact_cus p {
    margin-left: 12px;
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #263238;
}

.headingCost {
    display: flex;
    align-items: center;
}

.total2 .headingCost p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 1.001rem;
    line-height: 23px;
    color: #393F42;
}

.total2 .headingCost h4 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 30px;
    text-align: right;
    color: #24B7A4;
}

.imageUploadWrapper {
    background-color: #F7F7F7;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 10px 10px;
}

.imageUploadWrapper label {
    margin: 97px 0px;

}

.imageUploadWrapper label svg {
    margin-bottom: 12px;
}

.imageUploadWrapper label p {
    color: #4A4A4A;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.videoUpload {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
}


.videoUpload p {

    font-weight: 700;
    font-size: 14px;

    height: auto;
    /* background-color: blue; */
    color: #4A4A4A;
    line-height: 20px;
}

.uploadedImageWrapper {
    display: flex;
    gap: 10px;
    object-fit: contain;
    width: 100%;
}

.uploadedImageWrapper_inner {
    height: inherit;
}

.uploadedImageWrapper_inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    background-color: #F7F7F7;
}

.uploadedImageWrapper_container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 79px;
    margin: 10px 3px;
}

.plateNumber_input {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 48px;
}

.register_head p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #3C3E3F;
}

.home_unregistered_vehicles {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.carType {
    display: flex;
    margin: auto;
    width: 256px;
    height: auto;
}

.buyDongle__wrapper {
    display: flex;
    width: 100%;
    height: auto;
}

.buyDongle__wrapper img {
    width: 100%;
    margin: auto;
    height: auto;
    object-fit: cover;
}

.buyDongle__order2 {
    display: flex;
    margin: 0.9375rem;
    background-color: white !important;
    border: 1px solid #a9a9a9;
    justify-content: space-between;
    padding: 1.375rem 1rem;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
}


.buyDongle__order2 .buyDongle__order__text {
    justify-content: center;
    color: #3C3E3F;
    flex: 1;
    margin-right: 12px;
    max-width: 60%;
    margin-right: 42px;
    align-items: center;
    flex-direction: column;
}

.buyDongle__order2 .buyDongle__order__text h3 {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
}

.buyDongle__order2 .buyDongle__order__text p {
    font-weight: 450;
    font-size: 12px;
}

.buyDongle__order__button {
    width: 30%;
}

.buyDongle__order__button a {
    text-decoration: none;
}

.insurance_demo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.insurance_demo_header {
    display: flex;
    padding: 8px 15px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: var(--Color-grey-107, #F1F1F1);
}

.insurance_demo_header p {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.insurance_demo_video {
    width: 345px;
    height: 207px;
    flex-shrink: 0;
    align-self: center;
    display: flex;
    border-radius: var(--Border-radius-M, 4px);
    background: lightgray;
}

.insurance_demo_texts {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 15px;
}



.insurance_demo_texts p {
    color: #4A4A4A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.kyc_button {
    padding: 8px 15px;

}