.head {
    margin: 0px 15px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 85px;
}

.form {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 7px 8px;
    gap: 6px;
}