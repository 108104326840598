.menu__header {
    z-index: 10000;
    width: 80%;
    position: absolute;
}

.menu__modal__inner {
    /* padding: 0px 16px; */
    height: 100vh;
    background-color: white;
    width: 85%;
    color: white;
}

.modal__header {
    display: flex;
    align-items: center;
    background: var(--Color-linear, linear-gradient(187deg, #008069 26.79%, #35D853 113.67%));
}

.wrapper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(186.9deg, #008069 26.79%, #35D853 113.67%);
}

.wrapper img {
    width: 100%;
}

.modal__header div {
    align-items: center;
    margin-top: 33px;
    margin-bottom: 35px;
    margin-left: 12px;
}

.menu__modal__inner h4 {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
}

.menu__modal__inner p {
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
}

.menu__modal__list {
    display: flex;
    flex-direction: column;
    height: 80vh;

    overflow: auto;
}

.nav_container {
    display: flex;
    width: 100%;
    height: 48px;
    align-items: center;
    gap: 12px;
}

.nav_container a div {
    display: flex;
    gap: 12px;
    align-items: center;
}

.nav_container a {
    text-decoration: none;
    display: flex;
    gap: 12px;
    padding: 16px 24px;
    width: 100%;
}

.nav_container .active {
    background: var(--color-green-101, #03C962);
    display: flex;
    width: 100%;
    height: 48px;
    padding: 16px 24px;
    align-items: center;
    gap: 12px;
}

.nav_container .active p {
    color: #FFF;
}

.nav_container .active .svg svg path {
    fill: white;
    stroke: white;
}

.logout .title p {
    color: #FD4D1E;
}

.nav_container p {
    color: var(--color-green-101, #03C962);
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}