.buyDongle__order2 {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    padding: 22px 16px;
    background-color: #D7FBDB;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.buyDongle__order2 .buyDongle__order__text {
    justify-content: center;
    color: #FFFFFF;
    flex: 1;
    margin-right: 12px;
    max-width: 50%;
    margin-right: 42px;
    align-items: center;
    flex-direction: column;
}

.buyDongle__order2 .buyDongle__order__text h3 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 8px;
}

.buyDongle__order2 .buyDongle__order__text p {
    font-weight: 450;
    font-size: 11px;
}