.input_wrapper {
    margin: 0px;
    width: 100%;
    display: flex;
    flex: 1;
}

.input {
    width: 100%;
    outline: none;
    border: 1px solid #8EA396;
    border-radius: 6px;
    padding: 14px 8px;
    display: flex;
    background-color: white;
}

.input label {
    font-size: 10px;
    margin: 0px;
}