@media only screen and (max-width: 920px) {
    .liveMetrics {
        width: 100%;
    }

    .liveMetrics__inner_body {
        height: 45vh;
        overflow: auto;
    }

    .header {
        display: flex;
        padding: 16px 0px;
        margin: 0px 5px;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .header div {
        display: flex;
    }

    .header .signs div span {
        font-weight: 450;
        margin-left: 4px;
        font-size: 12px;
        line-height: 10px;
        color: #3C3E3F;
    }

    .title p {
        font-weight: 450;
        font-size: 17px;
        line-height: 15px;
        color: #3C3E3F;
    }

    .green {
        width: 8px;
        height: 9px;
        border-radius: 50%;
        background-color: #0AB34E;
    }

    .red {
        width: 8px;
        height: 9px;
        border-radius: 50%;
        background-color: #FD4D1E;
    }

    .yellow {
        width: 8px;
        height: 9px;
        border-radius: 50%;
        background-color: #FFB200;
    }

    .signs {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        /* background-color: red; */
    }

    .signs div {
        margin-left: 25px;
    }

    .liveMetrics__inner {
        background: #FFFFFF;
        border-radius: 12px;
        display: flex;
        padding: 5px 13px;
        flex-direction: column;
    }

    .liveMetrics__inner_summary {
        display: flex;
        justify-content: space-between;
    }

    .liveMetrics__inner_summary div {
        /* background-color: red; */
        width: 45%;
    }

    .liveMetrics__inner_summary div h4 {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #3C3E3F;
    }

    .liveMetrics__inner_summary div p {
        font-weight: 450;
        font-size: 10px;
        line-height: 13px;
        color: #BABABA;
    }

    .icon {
        display: flex;
        /* background-color: red; */
        justify-content: center;
    }

    .end {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .demacation {
        background-color: #BABABA;
        max-width: 0.5%;
        flex: 1;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        height: 30px;
    }

    .liveMetrics__inner_body {
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        padding: 5px 10px;
    }

    .liveMetrics__inner_body_content {
        display: flex;
        margin: 10px 0px;
        justify-content: space-between;
    }

    .liveMetrics__inner_body_content div {
        width: 90%;
    }

    .liveMetrics__inner_body_content_body {
        padding: 20px 8px;
        height: 100px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 128, 105, 0.2);
        border-radius: 8px;
        margin-left: 6px;
        margin-bottom: 2px;
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
    }

    .liveMetrics__inner_body_content_body h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 400;
    }

    .liveMetrics__inner_body_content_body svg {
        display: flex;
        margin: 12px 0px;
        justify-content: center;
        align-items: center;
    }

    .liveMetrics__inner_body_content_body p {
        font-weight: 450;
        font-size: 10px;
        line-height: 13px;
        color: #3C3E3F;
        display: flex;
        justify-content: center;
    }

    .fuelGuage_scale svg {
        z-index: 1000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    .fuelGuage_scale {
        width: 48px;
        height: 100%;
        flex: 1;
        max-width: 80%;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .fuelGuage_measurment {
        width: 100%;
        transition: all 0.3s ease-out;
        margin-left: 3.2px;
        border-radius: 0px 0px 8px 8px;
    }
}