.vehicleDetails_2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.vehicleDetails_2_inner {
    width: 100%;
}

.vehicleDetails_2_head {
    background: #F1F1F1;
    display: flex;
    width: 100%;
    padding: 0px 0px;
    align-items: center;
    gap: 20px;
}

.vehicleDetails_2 .vehicleDetails_2_head p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}


.vehicleDetails_2 p {
    gap: 10;
    margin: 0rem;
    margin: 0rem 0.25rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.vehicle_images {
    width: 100%;
    display: flex;
    height: 70px;
    padding: 0px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: var(--Grey-100, #F3F3F3);
}



.vehicleList_wrapper {
    width: 17.25em;
    height: 3.6875em;
    display: flex;
    padding: 20px 0px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    overflow-x: scroll;
    overflow-y: hidden;
}

.img {
    width: 75px;
    height: 48.75px;
    display: block;
    object-fit: contain;
}

.unactiveImg {
    width: 45px;
    height: 29.25px;
    display: block;
    object-fit: contain;
}

.add_vehicles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5.3125em;
}

.add_vehicles a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.add_vehicles p {
    color: #03C962;
    text-decoration: none;
    text-align: center;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}