.head {
    margin-top: 50px;
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
}

.error_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.carType {
    display: flex;
    margin: auto;
    width: 256px;
    height: auto;
}

.buyDongle__wrapper {
    display: flex;
    height: 7.5em;
    width: 12.5em;
    height: auto;
    align-self: center;
}

.buyDongle__wrapper img {
    width: 100%;
    margin: auto;
    height: auto;
    object-fit: cover;
}

.report_personal_info {
    margin: 0px 15px;
}

.ai_btn {
    margin: 0px 15px;
    margin-bottom: 10px;
}

.ai_btn a {
    text-decoration: none;
}

.vehicleInfo {
    margin: 0px 15px;
}

.button {
    margin: 30px 15px;
}

.system_codes_head {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 4px;
}

.system_codes h3 {
    font-weight: 500;
    padding: 12px 5px;
    margin: 0px;
    width: 100%;
    font-size: 16px;
    background-color: #E7E7E7;
    line-height: 20px;
    color: #263238;
}

.loading {
    margin-top: 100px;
}

.system_codes p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #263238;
}

.system_codes {
    width: 100%;
}

.user_summary {
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    padding: 0px 15px;
    background: var(--color-green-02, #D7FBDB);
}

.user_summary h3 {
    color: #3C3E3F;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.user_summary p {
    color: #3C3E3F;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.vehicleInfo {
    margin: 0px 16px;
}