.history {
    margin-top: 68px;
    display: flex;
    flex-direction: column;
    gap: 1.5625rem;
}

.history a {
    text-decoration: none;
}

.buyDongle__wrapper {
    display: flex;
    width: 100%;
    height: 120px;
}

.papers_head {
    display: flex;
    width: 100%;
    padding: 0px 0px;
    gap: 12px 0px;
    justify-content: space-between;
    align-items: flex-end;
}


.papers_head div {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-end;
    /* flex: 1; */
    /* max-width: 50%; */
}

.papers_head div p {
    color: var(--color-dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
}

.papers_head div div {
    display: flex;
    align-items: center;
    gap: 2px;
}

.papers_head div div p {
    color: var(--Body-10-Center, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 12px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.buyDongle__wrapper img {
    width: 100%;
    margin: auto;
    height: auto;
    object-fit: cover;
}

.carType {
    display: flex;
    margin: auto;
    width: 256px;
    height: auto;
}

.head {
    margin: 0px 15px;
}

.main_hero {
    display: flex;
    flex-direction: column;
    /* margin-top: 60px; */
    margin-bottom: 85px;
}

.history_list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
}

.list_wrapper {
    height: 50vh;
    overflow: auto;
    display: flex;
    gap: 0.75rem;
    flex-direction: column;
}

.list {
    display: flex;
    width: 100%;
    padding: 16px 15px;
    align-items: center;
    gap: 12px;
    border-radius: 6px;
    background: #FFF;
}

.list div {
    flex: 1;
    flex-direction: column;
    display: flex;
    width: 9.5em;
    margin: 10px 5px;
}

.list div:nth-child(2) {
    align-items: flex-end;
}

.list div p {
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #8EA396;
}

.list div h5 {
    font-weight: 700;
    font-size: 14px;
    color: #4A4A4A;
    line-height: 18px;
}

.list div .title {
    color: #EF5DA8;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form div .input {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
}

.list_info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.calender_filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
}

.calender_filter div {
    flex: 1;
}

.filterButton {
    width: 100%;
}

.filterButton div svg {
    margin: 0px 9px;
}

.body {
    display: flex;
    width: 100%;
    padding: 12px 15px;
    align-items: center;
    gap: 12px;
    background: #F6F4F4;
    border: 1px solid #373a3b;
    border-radius: 6px;
}

.inspection_report_list {
    display: flex;
    margin-top: 0.75rem;
    flex-direction: column;
    overflow-y: auto;
    height: 250px;
    gap: 0.75rem;
}

.body p {
    flex: 1;
    gap: 8px;
    display: flex;
    align-items: center;
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.body p span {
    color: var(--color-dark-grey-100, #263238);
    font-family: Circular Std;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.vehicleDetails_2 .VehicleInformation {
    padding: 10px 0px;
    margin-top: 5px;
}

.vehicleDetails_2_head {
    background: #F1F1F1;
    width: 100%;
    padding: 5px 0px;
    margin-top: 5px;
}

.vehicleDetails_2 .vehicleDetails_2_head p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.vehicleDetails_2 {
    margin: 0px;
    width: 100%;
}

.vehicleDetails_2_head p {
    margin-left: 15px !important;
}

.vehicleDetails_2 .VehicleInformation:nth-child(1) {
    background: #F7F7F7;
}

.vehicleDetails_2 .VehicleInformation:nth-child(1) p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.vehicleDetails_2 p {
    gap: 10;
    margin: 0px;
    margin: 10px 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
}

.VehicleInformation {
    display: flex;
    margin: 0px 15px;
}

.VehicleInformation div {
    margin-right: 20px;
}

.VehicleInformation div:nth-child(2) p:nth-child(1),
.VehicleInformation div:nth-child(2) p:nth-child(2) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3C3E3F;

}

.VehicleInformation div:nth-child(2) {
    flex: 1;
    width: 50%;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 13px 0px;
    gap: 28px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12), 0px 16px 16px rgba(50, 50, 71, 0.08);
    border-radius: 10px;
    width: 100%;
    margin: 15px 0px;

}

.car_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px 1rem;
    margin: 0px 0px;
}

.car_details_inner {
    margin: 13px 16px;
    width: 100%;
}

.car_details_info {
    display: flex;
    margin-bottom: 12px;
    justify-content: space-between;
}

.car_details_row {
    flex: 1;
    max-width: 50%;
    display: flex;
    align-items: center;
}

.car_details_row p,
.car_details_row h3 {
    margin: 0px;
}

.car_details_row svg {
    margin-right: 8px;
}

.car_details_row p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 10px;
    line-height: 15px;
    color: #8EA396;
}

.car_details_row h3 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}

.car_details p {
    margin: 0px;
}

.card_personal_info_inner {
    margin: 13px 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0px;
}

.image_outer {
    display: flex;
    align-items: center;
}

.card_personal_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.user_info h4 {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    margin: 0px;
    align-items: center;
    color: #263238;
}

.user_info p {
    font-family: 'Circular Std';
    font-style: normal;
    font-weight: 450;
    font-size: 11px;
    line-height: 14px;
    margin: 0px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Color/grey green */

    color: #8EA396;
}

.image {
    display: flex;
    width: 3.125em;
    height: 3.125em;
    border-radius: 12px;
    margin-right: 12px;
}

.image .image_wrapper {
    display: flex;
    width: 100%;
    height: auto;
}

.image .image_wrapper img {
    width: 100%;
    height: auto;
    display: flex;
}

.vehicleDetails_2_inner {
    width: 100%;
    gap: 8px;
    display: flex;
    margin: 16px 0px;
}

.vehicleDetails_2_inner_items {
    width: 100%;
    justify-content: space-between;
    padding: 5px 15px;
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border-radius: var(--Border-radius-M, 4px);
    border: 1px solid #E0E0E0;
    background: var(--color-green-02, #F6F4F4);
}

.vehicleDetails_2_inner_items p {
    color: var(--Color-Dark-grey-100, #263238);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Header 5 B Left */
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.vehicleDetails_2_inner_wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

@media only screen and (max-width: 320px) {
    .VehicleInformation {
        margin: 0px 0px;
    }
}