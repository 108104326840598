.head {
    margin: 0px;
    margin: 50px 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.motor_ai_head {
    margin: 0px;
    height: 90vh;
    display: flex;
    flex-direction: column;
}

.iframe {
    display: flex;
    flex: 1;
}

.fault_code {
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin: 0rem 0.9375rem;
    height: 40px;
    padding: 8px 15px;
    align-items: center;
    margin-bottom: 8px;
    background: var(--color-grey-107, #F1F1F1);
}

/* .fault_code p {
    color: #FD4D1E;
} */
.error_wrapper {
    margin: 0rem 0.9375rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.fault_code h1 {
    color: var(--color-dark-grey-100, #263238);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.main_hero {
    display: flex;
    flex-direction: column;
    margin: 0px 15px;
    margin-top: 50px;
}

.buyDongle__order2 {
    display: flex;
    margin: 0.9375rem;
    justify-content: space-between;
    padding: 1.375rem 1rem;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
}


.car_health_card {
    display: flex;
    padding-bottom: 24px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background-color: #FFFF !important;
}

.car_health_card_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}

.car_health_card_text h3 {
    color: var(--Color-dark-grey-1, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.car_health_card_text p {
    color: var(--Color-dark-grey-1, #3C3E3F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Circular Std";
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
}

.car_health_card_btn {
    width: 100%;
}

.buyDongle__order2 .buyDongle__order__text {
    justify-content: center;
    color: #3C3E3F;
    flex: 1;
    margin-right: 12px;
    max-width: 60%;
    margin-right: 42px;
    align-items: center;
    flex-direction: column;
}

.buyDongle__order2 .buyDongle__order__text h3 {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 8px;
}

.buyDongle__order2 .buyDongle__order__text p {
    font-weight: 450;
    font-size: 12px;
}

.buyDongle__order__button {
    width: 30%;
}

.buyDongle__order__button a {
    text-decoration: none;
}